import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/statuspage/status.css"; // Import your CSS file for styling
import baseUrl from "../../config/config";
import { BsInfoCircle } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { VscHistory } from "react-icons/vsc";
import "../../styles/table/table.css";
import "../../styles/allAssets/allAssets.css";
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaTrashRestore } from "react-icons/fa";
import Popupq from "../../components/popupq/PopupQ";
import api from "../../api/api";
import icons from "../../data/assetTypes";
import status from "../../data/status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdSpeaker } from "react-icons/md";
import { GrPowerReset } from "react-icons/gr";
import { Tooltip } from "react-tooltip";
import useDebounce from "../../functions/useDeboune";
import DatePicker from "react-datepicker";
import ExcelExportButton from "./ExportButton/ExcelExportButton";

const AllAssets = ({ setActiveIndex }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token"); //retrive the token from the local storage
  const [details, setDetails] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  const [showObsolete, setShowObsolete] = useState(false);
  const [filterdDetails, setFilterdDetails] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [rowCount, setRowCount] = useState();
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem("currentPage");
    return savedPage ? parseInt(savedPage, 10) : 1; // Default to page 1 if not found
  }); // Current page number
  const [selectedTab, setSelectedTab] = useState("All");
  const [showPopup, setShowPopup] = useState(false);
  const itemsPerPage = 9;
  const id = localStorage.getItem("user_id");
  const isAdmin = localStorage.getItem("access_type");
  const event = "Deleted";
  const [activityData, setActivityData] = useState({
    assetNumber: "",
    employeeId: id,
    event: event,
  });
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [filteredAssetType, setFilteredAssetType] = useState([]);
  const [assetTypeFilter, setAssetTypeFilter] = useState();
  const [selectType, setSelectType] = useState(null);
  const [assetIcons, setAssetIcons] = useState(null);
  const [displayedOrders, setDisplayedOrders] = useState([]);
  const [datepickerSelected, setDatepickerSelected] = useState("");
  const [statusFilter, setStatusFilter] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [search, setSearch] = useState("");
  const [resultCount, setResultCount] = useState("");
  const [combinedType, setCombinedType] = useState(
    localStorage.getItem("assetType") || localStorage.getItem("selectedTab")
  );
  const [combinedSearch, setCombinedSearch] = useState("");
  const [combinedFilter, setCombinedFilter] = useState("");
  const debouncedSearch = useDebounce(combinedSearch, 500);
  const [load, setLoad] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setLoad(true);
    fetchDetails(debouncedSearch, combinedFilter, combinedType, showObsolete);
  }, [debouncedSearch, combinedFilter, combinedType, showObsolete]);

  useEffect(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayOrders = filterdDetails?.slice(
      indexOfFirstItem,
      indexOfLastItem
    ); // assuming you meant 'details'
    setDisplayedOrders(displayOrders);
  }, [currentPage, details, filterdDetails]);

  useEffect(() => {
    const searchParts = [];
    const filterParts = [];
    const typeParts = [];

    if (search) searchParts.push(search);
    if (statusFilter) filterParts.push(statusFilter);
    if (datepickerSelected)
      filterParts.push(new Date(datepickerSelected).getFullYear().toString());
    if (assetTypeFilter) typeParts.push(assetTypeFilter);

    setCombinedSearch(searchParts.join(","));
    setCombinedFilter(filterParts.join(","));
    setCombinedType(typeParts.join(","));
  }, [search, statusFilter, datepickerSelected, assetTypeFilter]);

  const fetchDetails = async (
    combinedSearch = "",
    combinedFilter = "",
    combinedType = localStorage.getItem("assetType") ||
      localStorage.getItem("selectedTab"),
    showObsolete = false
  ) => {
    try {
      if (!token) {
        navigate("/login");
        return; // Exit if no token
      }
      // Retrieve and parse the Status data from local storage
      const statusData = JSON.parse(localStorage.getItem("Status"));
      const statusLabel = statusData ? statusData.label : "";

      // Retrieve the Year from local storage
      const year = localStorage.getItem("Year");

      // Safely convert year to a valid year string or use an empty string if invalid
      const yearString = year ? new Date(year).getFullYear().toString() : "";

      // Combine status label and year string with a comma separator, default to empty string if both are empty
      const combinedFilterValue =
        [statusLabel, yearString].filter(Boolean).join(",") || "";

      // Use an empty string if combinedFilter is NaN or undefined
      const safeCombinedFilter = combinedFilter || combinedFilterValue || "";

      const searchResult = localStorage.getItem("Search") || "";

      const response = await api.get(`${baseUrl}/api/all-assets-details`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        params: {
          combinedSearch: combinedSearch || searchResult,
          combinedFilter: safeCombinedFilter,
          combinedType,
          showObsolete,
        },
      });

      const assetData = response.data.results;
      setAllAssets(assetData);
      setDetails(assetData);
      setResultCount(assetData.length);
      if (response) {
        callFn(response.data.assetTypeCounts);
      }
      setTimeout(() => {
        setLoad(false);
      }, 2000);
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const toggleObsolete = () => {
    setCurrentPage(1);
    setDatepickerSelected("");
    setStatusFilter("");
    setSelectedStatus("");
    setSearch("");
    setAssetTypeFilter("All");
    localStorage.removeItem("Search");
    localStorage.removeItem("Year");
    localStorage.removeItem("Status");
    localStorage.removeItem("assetType");
    localStorage.removeItem("selectedTab");
    setIsInitialized(true); // Trigger the effect once all states are set
  };

  useEffect(() => {
    if (isInitialized) {
      localStorage.setItem("selectedTab", "All");
      setShowObsolete((prevShowObsolete) => !prevShowObsolete);
      setIsInitialized(false); // Reset the trigger
    }
  }, [isInitialized]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
    localStorage.setItem("Search", e.target.value);
  };

  const clearSearch = () => {
    setSearch("");
    localStorage.removeItem("Search");
  };

  const callFn = (datas) => {
    const iconsMap = {};
    datas.forEach((asset) => {
      const matchingIcon = icons.find(
        (icon) => icon["type"] === asset.assetType
      );
      if (matchingIcon) {
        iconsMap[asset.assetType] = matchingIcon["icon"];
      } else {
        console.warn(
          `No matching icon found for assetType: ${asset.assetType}`
        );
      }
    });
    setAssetIcons(iconsMap);
    setRowCount(datas.length);
    setAssetTypeList(datas);
  };

  const handleTabChange = (tab) => {
    setCurrentPage(1);
    setSelectedTab(tab);
    localStorage.setItem("selectedTab", tab);
    localStorage.removeItem("assetType");
    setAssetTypeFilter(tab);
    filterData(tab);
  };

  useEffect(() => {
    const tab = localStorage.getItem("selectedTab");
    const assetType = localStorage.getItem("assetType");
    if (tab && tab !== "null") {
      setSelectedTab(tab);
      setAssetTypeFilter(tab);
    }
    setAssetTypeFilter(assetType ? assetType : tab);
  }, displayedOrders);

  const filterData = (tab) => {
    let filterType = [];
    let filtered = [];

    setSelectType(tab);
    if (tab === "All") {
      filtered = details; // Show all data
      filterType = assetTypeList;
    } else if (tab === "Primary") {
      filtered = details.filter(
        (asset) =>
          asset.assetType &&
          asset.assetType.assetCategory &&
          asset.assetType.assetCategory.toLowerCase() === "primary"
      );
      filterType = assetTypeList.filter(
        (asset) =>
          asset.assetType &&
          asset.assetCategory &&
          asset.assetCategory.toLowerCase() === "primary"
      );
    } else if (tab === "Secondary") {
      filtered = details.filter(
        (asset) =>
          asset.assetType &&
          asset.assetType.assetCategory &&
          asset.assetType.assetCategory.toLowerCase() === "secondary"
      );
      filterType = assetTypeList.filter(
        (asset) =>
          asset.assetType &&
          asset.assetCategory &&
          asset.assetCategory.toLowerCase() === "secondary"
      );
    }
    setFilterdDetails(filtered);
    setFilteredAssetType(filterType);
  };

  const handleStatusChange = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const label = selectedOption.text;
    const value = event.target.value;
    setStatusFilter(label);
    setSelectedStatus(value);
    const statusData = { label, value };
    localStorage.setItem("Status", JSON.stringify(statusData));
  };

  const filteredStatusOptions = showObsolete
    ? status.filter((option) => option.label === "Obsolete")
    : status.filter((option) => option.label !== "Obsolete");

  const clearSelection = () => {
    setStatusFilter("");
    setSelectedStatus("");
    localStorage.removeItem("Status");
  };

  const handleYearChange = (date) => {
    setDatepickerSelected(date);
    localStorage.setItem("Year", date);
  };

  const clearDatepicker = () => {
    setDatepickerSelected("");
    localStorage.removeItem("Year");
  };

  const handleTypeChange = (assetType) => {
    setCurrentPage(1);
    setSelectType(assetType);
    setAssetTypeFilter(assetType);

    localStorage.setItem("assetType", assetType);
  };

  const clearType = () => {
    setAssetTypeFilter(selectedTab);
    localStorage.removeItem("assetType");
  };
  useEffect(() => {
    const statusData = JSON.parse(localStorage.getItem("Status")) || "";
    const year = localStorage.getItem("Year") || "";
    const search = localStorage.getItem("Search") || "";
    if (statusData) {
      const { label, value } = statusData;
      setStatusFilter(label);
      setSelectedStatus(value);
    }
    setDatepickerSelected(year);
    setSearch(search);
  });

  useEffect(() => {
    fetchDetails(debouncedSearch, combinedFilter, combinedType, showObsolete);
  }, [id]);
  useEffect(() => {
    if (details) {
      filterData(selectedTab);
    }
  }, [details, selectedTab]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    localStorage.setItem("currentPage", newPage);
  };

  const handleViewFunction = (objectID, assetNumber) => {
    navigate(`/asset-details/${objectID}/${encodeURIComponent(assetNumber)}`);
  };

  const handleDelete = async (item) => {
    // Show a confirmation dialog for deleting the main asset
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this device entry?"
    );
    if (userConfirmed) {
      try {
        if (!token) {
          navigate("/login");
        } else {
          // Delete the main asset
          await api.delete(
            `${baseUrl}/api/asset-delete/${item.assetDetails._id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the Authorization header
              },
            }
          );

          // Prepare activity details
          const activityDetails = {};
          if (item.assetDetails.assetNumber) {
            activityDetails.assetNumber = item.assetDetails.assetNumber;
            activityDetails.employeeId = activityData.employeeId;
            activityDetails.event = activityData.event;
          }

          // Add activity
          await api.post(`${baseUrl}/api/add-activity`, activityDetails, {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          });

          if (item.assetType.assetCategory === "primary" && details) {
            // Ask user if they want to delete all subassets
            const deleteSubAssetsConfirmed = await new Promise((resolve) => {
              const resolveFunction = (value) => {
                resolve(value);
              };
              setShowPopup({ visible: true, resolveFunction });
            });

            if (deleteSubAssetsConfirmed) {
              // Delete all subassets
              for (const asset of details) {
                if (
                  asset.assetDetails.parentAsset ===
                  item.assetDetails.assetNumber
                ) {
                  await api.delete(
                    `${baseUrl}/api/asset-delete/${asset.assetDetails._id}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                      },
                    }
                  );
                }
              }
            } else {
              // Update subassets to remove the parentAsset reference
              for (const asset of details) {
                if (
                  asset.assetDetails.parentAsset ===
                  item.assetDetails.assetNumber
                ) {
                  let newAsset = { ...asset, parentAsset: "" };
                  await api.post(
                    `${baseUrl}/api/device-update/${asset.assetDetails._id}`,
                    newAsset,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
                      },
                    }
                  );
                }
              }
            }
          }

          // Navigate to all assets and fetch details
          navigate(`/all-assets`);
          fetchDetails();
        }
      } catch (error) {
        console.error("Error deleting entry:", error);
        // Optionally, set error status here
        // setDeleteStatus('Error deleting entry');
      }
    } else {
      alert("Error deleting asset");
    }
  };

  const handleUpdate = (objId, assetNumber) => {
    navigate(`/update/${encodeURIComponent(assetNumber)}/${objId}`);
  };

  const BackToHome = () => {
    navigate("/");
  };

  const registerDevice = () => {
    navigate(`/register/${id}`);
  };

  useEffect(() => {
    if (details) {
      setFilterdDetails(details);
      setRowCount(details.length);
    }
  }, [details]);

  const handleLog = (assetNumber) => {
    setActiveIndex(4);
    navigate(`/activities/${encodeURIComponent(assetNumber)}`);
  };

  const getbscolor = (status) => {
    switch (status) {
      case "New/Verified":
        return "#ebf2ff";
      case "In stock":
        return "#f6f0ff";
      case "Assigned":
        return "#ecfdf3";
      case "Maintenance":
        return "#fbd5bf";
      case "Returned":
        return "#fcf3d7";
      case "Clearance Done":
        return "#ffe9f2";
      case "To Be Decommissioned":
        return "#fee8e7";
      case "Lost/Stolen":
        return "#ff9ca5";
      case "Obsolete":
        return "#ededf0";
      default:
        return {};
    }
  };

  const getcolor = (status) => {
    switch (status) {
      case "New/Verified":
        return "#76a9ff";
      case "In stock":
        return "#5c2680";
      case "Assigned":
        return "#008000";
      case "Maintenance":
        return "#e4521d";
      case "Returned":
        return "#f3ae3f";
      case "Clearance Done":
        return "#ff6eaa";
      case "To Be Decommissioned":
        return "#c8372d";
      case "Lost/Stolen":
        return "#811a00";
      case "Obsolete":
        return "#4a4b57";
      default:
        return {};
    }
  };

  return (
    <div className="content-section col-12 col-md-12 col-xl-11 asset-section">
      {showPopup.visible && (
        <Popupq
          message="Are you sure you want to delete these sub-assets?"
          data1="Yes"
          data2="No"
          setShowPopup={() => setShowPopup({ visible: false })}
          resolveFunction={showPopup.resolveFunction}
        />
      )}
      <div className="asset-all">
        <div className="asset-head">
          <div className="main-heading">
            <h3>{!showObsolete ? "All Assets" : "Obsolete Assets"}</h3>
          </div>
        </div>
        <div className="asset-content d-flex flex-column flex-md-column flex-lg-column flex-xl-row">
          <div className="asset-list all-assets-page-lists col-12 col-md-12 col-lg-12 ">
            <div className="asset-tab-bar">
              <button
                className={selectedTab === "All" ? "active" : ""}
                onClick={() => handleTabChange("All")}
              >
                All
              </button>
              <button
                className={selectedTab === "Primary" ? "active" : ""}
                onClick={() => handleTabChange("Primary")}
              >
                Primary
              </button>
              <button
                className={selectedTab === "Secondary" ? "active" : ""}
                onClick={() => handleTabChange("Secondary")}
              >
                Secondary
              </button>
            </div>

            <div className="all-asset-typelist-head-section w-100 w-md-50 w-lg-50 w-xl-100">
              <div className="item-heading">
                <span>Asset Type Name</span>
              </div>
              <div className="item-heading">
                <span>Count</span>
              </div>
            </div>
            {details && details.length > 0 ? (
              <div className="asset-list-section">
                {filteredAssetType.map((item, index) => (
                  <div className="asset-list-item-main">
                    <button
                      className={
                        assetTypeFilter === item.assetType &&
                        assetTypeFilter != null
                          ? "asset-list-item activeType"
                          : "asset-list-item"
                      }
                      onClick={() => handleTypeChange(item.assetType)}
                    >
                      <div className="asset-type-img"></div>
                      <div className="asset-type">
                        <span>{item.assetType}</span>
                      </div>
                      <div className="asset-count">
                        <span>{item.count}</span>
                      </div>
                    </button>
                    {assetTypeFilter && assetTypeFilter === item.assetType && (
                      <button
                        className="clearTypeBtn"
                        onClick={clearType}
                        style={{ cursor: "pointer" }}
                      >
                        &#x2715;{" "}
                        {/* This is the Unicode character for a multiplication sign (x) */}
                      </button>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p>No records found</p>
            )}
          </div>
          <div className="asset-list-data col-12 col-md-12 col-lg-12 ">
            <div className="asset-list-heading flex-column flex-xl-row align-items-start align-items-xl-center">
              <div className="d-flex justify-content-center align-items-center">
                <h5>{assetTypeFilter ? assetTypeFilter : "All"}</h5>
                <span className="mx-2">{"(" + resultCount + ")"}</span>
                <div className="">
                  <ExcelExportButton
                    data={filterdDetails} // Filtered data based on selected tab and filters
                    assetTypeFilter={assetTypeFilter} // The current asset type filter
                    allAssets={allAssets}
                  />
                </div>
              </div>
              <></>
              <div className="asset-filter d-flex flex-column flex-md-row flex-xl-row align-items-start">
                <div className="asset-search-filter">
                  <input
                    type="text"
                    placeholder="Search..."
                    value={combinedSearch}
                    onChange={handleSearch}
                  />
                  {search && (
                    <button onClick={clearSearch} style={{ cursor: "pointer" }}>
                      &#x2715;{" "}
                      {/* This is the Unicode character for a multiplication sign (x) */}
                    </button>
                  )}
                </div>
                <div className="asset-date-filter">
                  <DatePicker
                    selected={datepickerSelected}
                    onChange={handleYearChange}
                    showYearPicker
                    dateFormat="yyyy"
                    className="date-own form-control"
                    placeholderText="Select year"
                  />
                  {datepickerSelected && (
                    <button
                      onClick={clearDatepicker}
                      style={{ cursor: "pointer" }}
                    >
                      &#x2715;{" "}
                      {/* This is the Unicode character for a multiplication sign (x) */}
                    </button>
                  )}
                </div>
                <div
                  className="statusFilter"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <select
                    name="status_filter"
                    onChange={handleStatusChange}
                    value={selectedStatus}
                  >
                    <option value="">Select Status..</option>
                    {filteredStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  {statusFilter && (
                    <button
                      onClick={clearSelection}
                      style={{ cursor: "pointer" }}
                    >
                      &#x2715;{" "}
                      {/* This is the Unicode character for a multiplication sign (x) */}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {load && load === true ? (
              <div className="d-flex align-items-center justify-content-center h-100 w-100">
                <div className="d-flex flex-column align-items-center">
                  <div className="loading-image">
                    <img
                      src="/images/fade-stagger-circles.svg"
                      alt="Profile Pic"
                      className="loading-image-item"
                    />
                  </div>
                  <div className="loading-text">Loading</div>
                </div>
              </div>
            ) : details && details.length > 0 ? (
              <div className="row asset-items">
                <div className="row asset-items-inner">
                  {displayedOrders.map((item, index) => (
                    <div className="asset-item-main col-12 col-md-6 col-lg-6 ">
                      <div className="asset-item h-100">
                        <div className="asset-content-inner  col-12">
                          <div className="asset-innersec">
                            <div className="asset-item-image-title">
                              <div className="asset-item-image-inner">
                                {item?.assetType?.assetType === "Speaker" ? (
                                  <MdSpeaker />
                                ) : (
                                  <FontAwesomeIcon
                                    className="font-icons"
                                    icon={
                                      assetIcons[item?.assetType?.assetType]
                                    }
                                  />
                                )}
                              </div>
                              <div className="asset-item-number">
                                {item.assetDetails.assetNumber}
                              </div>
                            </div>

                            <div className="asset-item-action">
                              {/* {hoveredRow === index ? ( */}
                              <div className="action-icons">
                                <BsInfoCircle
                                  className="action-icon"
                                  onClick={() =>
                                    handleViewFunction(
                                      item.assetDetails._id,
                                      item.assetDetails.assetNumber
                                    )
                                  }
                                />
                                {isAdmin && isAdmin === "1" && (
                                  <>
                                    <FiEdit3
                                      className="action-icon"
                                      onClick={() =>
                                        handleUpdate(
                                          item.assetDetails._id,
                                          item.assetDetails.assetNumber
                                        )
                                      }
                                    />
                                    <BsTrash
                                      className="action-icon"
                                      onClick={() => handleDelete(item)}
                                    />
                                  </>
                                )}
                                <VscHistory
                                  className="action-icon"
                                  onClick={() =>
                                    handleLog(item.assetDetails.assetNumber)
                                  }
                                />
                              </div>
                              {/* ) : (
                                                                <BsThreeDotsVertical className="three"  onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave} />
                                                                )} */}
                            </div>
                          </div>

                          <div className="asset-item-type">
                            <span className="asset-label">Asset Type: </span>
                            <span className="asset-data">
                              {item.assetType?.assetType}
                            </span>
                          </div>

                          <div className="asset-item-category">
                            <span className="asset-label">Category: </span>
                            <span className="asset-data">
                              {item.assetType?.assetCategory}
                            </span>
                          </div>

                          <div
                            className={
                              item.allocatedPersonDetails
                                ? "asset-item-allocated d-flex flex-column flex-md-column flex-xl-row justify-content-between"
                                : "asset-item-allocated d-flex flex-column flex-md-column flex-xl-row justify-content-end"
                            }
                          >
                            {item.allocatedPersonDetails ? (
                              <div className="asset-allocated-person">
                                <span className="asset-label">User: </span>
                                <span className="asset-data">
                                  {item.allocatedPersonDetails.first_name +
                                    " " +
                                    item.allocatedPersonDetails.last_name}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="asset-status">
                              <span
                                key={item.assetDetails.status}
                                className={
                                  item.assetDetails.status == "Assigned"
                                    ? "active"
                                    : ""
                                }
                                style={{
                                  backgroundColor: getbscolor(
                                    item.assetDetails.status
                                  ),
                                  color: getcolor(item.assetDetails.status),
                                }}
                              >
                                {item.assetDetails.status}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {filterdDetails.length > itemsPerPage && (
                  <div className="pagination-container">
                    <div className="pagination">
                      <ul className="pagination-lists">
                        <li className="page-item-prev flex-center">
                          {currentPage !== 1 ? (
                            <a
                              onClick={() => handlePageChange(currentPage - 1)}
                              className="pagination-link"
                              href="#"
                            >
                              <HiOutlineArrowSmLeft className="nxt-prev-icon" />
                            </a>
                          ) : (
                            <HiOutlineArrowSmLeft className="nxt-prev-icon" />
                          )}
                        </li>
                        {Array.from(
                          { length: Math.min(rowCount, 5) },
                          (_, index) => {
                            const page = currentPage - 2 + index;
                            return page > 0 &&
                              page <= Math.ceil(rowCount / itemsPerPage) ? (
                              <li
                                key={index}
                                className={`page-item ${
                                  currentPage === page ? "active" : ""
                                }`}
                              >
                                <a
                                  onClick={() => handlePageChange(page)}
                                  className="page-link"
                                  href="#"
                                >
                                  {page}
                                </a>
                              </li>
                            ) : null;
                          }
                        )}
                        <li className="page-item-nxt flex-center">
                          {currentPage < Math.ceil(rowCount / itemsPerPage) ? (
                            <a
                              onClick={() => handlePageChange(currentPage + 1)}
                              className="pagination-link"
                              href="#"
                            >
                              <p className="nxt-button">
                                <HiOutlineArrowSmRight className="nxt-prev-icon" />
                              </p>
                            </a>
                          ) : (
                            <HiOutlineArrowSmRight className="nxt-prev-icon" />
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="no-data-section">
                <div className="no-data-image-section">
                  <img
                    src="/images/not-found.png"
                    alt="Profile Pic"
                    className="no-data-image"
                  />
                </div>

                <div className="no-data-text-section">
                  <p>No Records Found</p>
                  {/* <span>No assets are assigned to you now. Please check later for any new assignments.</span> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="toggle-button-container">
        <button
          className="toggle-button asset-item-image-inner"
          onClick={toggleObsolete}
        >
          {!showObsolete ? (
            <FaTrashRestore
              data-tooltip-id="tool-tip-bsolete-asset"
              data-tooltip-content="Obsolete Assets"
            />
          ) : (
            <GrPowerReset
              data-tooltip-id="tool-tip-reset-asset"
              data-tooltip-content="Reset View"
            />
          )}
        </button>
        <Tooltip
          id="tool-tip-bsolete-asset"
          className="asset-tooltip"
          border="1px solid #5c2680"
        />
        <Tooltip
          id="tool-tip-reset-asset"
          className="asset-tooltip"
          border="1px solid #5c2680"
        />
      </div>
    </div>
  );
};

export default AllAssets;
