import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select'
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import Popup from '../../components/popup/Popup';
import { useNavigate, useParams } from 'react-router-dom';
import baseUrl, { corporateUrl } from '../../config/config';
import '../../styles/assetModify/assetModify.css'; // Import the CSS file
import '../../styles/form/form.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUserCheck,faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import api from '../../api/api';
import { IoMdRefresh } from "react-icons/io";
import icons from '../../data/assetTypes';
import { Tooltip } from 'react-tooltip';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';

const Update = ({setActiveIndex}) => {

  const { id, objId } = useParams();
  const [options, setOptions] = useState([])
  const selectRef = useRef()
  const [initialAllocated,setInitiallyAllocated] = useState('');
  const event = 'Edited';
  const [instock,setIsInstock] = useState(false);
  const [details, setDetails] = useState(null);
  const token = localStorage.getItem('token'); //retrive the token from the local storage
  const emp_id = localStorage.getItem('user_id');
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    assetNumId: '',
    assetNumber: '',
    employeeId: Number(emp_id),
    assetType: '',
    assetTypeId: '',
    assetCategory: '',
    status: '',
    allocatedPerson:'',
    allocatedPersonId:'',
    seat_allocation: '',
    role: '',
    yearOfPurchase: '',
    remarks: '',
    parentAsset:'',
    fields:[]
  });
  const [updatedFormData, setUpdatedFormData] = useState({
    
  });
  const [activityData, setActivityData] = useState({
    assetNumber: '',
    employeeId: Number(emp_id),
    event: event,
    updatedFields:'',
  });
  const [popup, setPopup] = useState(null);
  const [allEmployees,setAllEmployees] = useState([])
  const [optionsData, setOptionsData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (!token) {
          navigate("/login")
        } else {
          const response = await api.get(`${baseUrl}/api/asset-detail/${objId}`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          const assetDetailsResponse = response.data[0];
          //setDetails(assetDetailsResponse);
          setDetails({
            assetNumId: assetDetailsResponse?.assetDetails?.assetNumId || '',
            assetNumber: assetDetailsResponse?.assetDetails?.assetNumber || '',
            assetType: assetDetailsResponse?.assetType?.assetType || '',
            assetTypeId: assetDetailsResponse?.assetType?.assetTypeId || '',
            assetCategory: assetDetailsResponse?.assetType?.assetCategory || '',
            status: assetDetailsResponse?.assetDetails?.status || '',
            allocatedPerson: assetDetailsResponse?.allocatedPersonDetails?.first_name || '',
            allocatedPersonId: Number(assetDetailsResponse?.allocatedPersonDetails?.employeeId) || '',
            seat_allocation: assetDetailsResponse?.allocatedPersonDetails?.seat_allocation || '',
            role: assetDetailsResponse?.allocatedPersonDetails?.role || '',
            yearOfPurchase: assetDetailsResponse?.assetDetails?.yearOfPurchase || '',
            remarks: assetDetailsResponse?.assetDetails?.remarks || '',
            parentAsset: assetDetailsResponse?.assetDetails?.parentAsset || '',
            fields: assetDetailsResponse?.fieldDetails?.map(field => {
              const spec = assetDetailsResponse.specifications.find(spec => spec.feildId === field.feildId) || {}; 
              return {
                fieldId: field.feildId || '', // Ensure fieldId is properly referenced
                fieldName: field.fieldName || '', // Ensure fieldName is properly referenced
                fieldValId: spec.fieldValId || '',
                fieldData: spec.fieldValue || ' ',
                fieldType: field.fieldType || '',
                options: field.options || [] ,// Assume options is an array of dropdown options
                mandatory:field.mandatory || ''
              };
            }) || []
          });
          setFormData({
            assetNumId: assetDetailsResponse?.assetDetails?.assetNumId || '',
            assetNumber: assetDetailsResponse?.assetDetails?.assetNumber || '',
            assetType: assetDetailsResponse?.assetType?.assetType || '',
            assetTypeId: assetDetailsResponse?.assetType?.assetTypeId || '',
            assetCategory: assetDetailsResponse?.assetType?.assetCategory || '',
            status: assetDetailsResponse?.assetDetails?.status || '',
            allocatedPerson: assetDetailsResponse?.allocatedPersonDetails?.first_name || '',
            allocatedPersonId: Number(assetDetailsResponse?.allocatedPersonDetails?.employeeId) || '',
            seat_allocation: assetDetailsResponse?.allocatedPersonDetails?.seat_allocation || '',
            role: assetDetailsResponse?.allocatedPersonDetails?.role || '',
            yearOfPurchase: assetDetailsResponse?.assetDetails?.yearOfPurchase || '',
            remarks: assetDetailsResponse?.assetDetails?.remarks || '',
            parentAsset: assetDetailsResponse?.assetDetails?.parentAsset || '',
            fields: assetDetailsResponse?.fieldDetails?.map(field => {
              const spec = assetDetailsResponse.specifications.find(spec => spec.feildId === field.feildId) || {}; 
              return {
                fieldId: field.feildId || '', // Ensure fieldId is properly referenced
                fieldName: field.fieldName || '', // Ensure fieldName is properly referenced
                fieldValId: spec.fieldValId || '',
                fieldData: spec.fieldValue || ' ',
                fieldType: field.fieldType || '',
                options: field.options || [] ,// Assume options is an array of dropdown options
                mandatory:field.mandatory || ''
              };
            }) || []
          });
          var assetStatus = assetDetailsResponse?.assetDetails?.status
          setIsInstock(assetDetailsResponse?.assetDetails?.status == 'In Stock' || assetDetailsResponse?.assetDetails?.status == 'New/Verified');
          setOptions(getDropdownOptions(assetStatus));
          console.log('data',formData.fields[0]);
          setActivityData({...activityData, event:event,assetNumber:response.data[0].assetDetails.assetNumber});
          setInitiallyAllocated(Number(formData.allocatedPersonId));
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };
    fetchDetails();
  }, [id]);

  useEffect(() => {

    getAllEmployees()
  }, [])

  const getAllEmployees = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`${corporateUrl}/api/all-users-asset`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
      })
      setAllEmployees(response.data);
      const options = response.data.map(employee => ({
        value: employee.employeeId,
        label: employee.first_name+' '+employee.last_name+' ( '+employee.employeeId+' ) ',
      }));
      setOptionsData(options);
      
    }catch (error) {
      console.error('Error fetching employees', error);
    }
  }

  useEffect(() => {
    getSelectedEmpData();
  }, [optionsData]);

  const getSelectedEmpData = async () => {
    if(optionsData.length !== null){
      const selectedData = optionsData.filter(selectData=> selectData.value === formData.allocatedPersonId );
      setSelectedOption(selectedData);
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
  
    if (name !== 'allocatedPerson' && value !== null) {
      // Update formData and activityData when a value is selected
      setFormData({
        ...formData,
        [name]: value,
        //allocatedPersonId: Number(value),
      });
  
      setUpdatedFormData({ ...updatedFormData,  [name]: value });

    } else {
      // Handle resetting formData and activityData when value is null or allocatedPerson is changed
      setFormData({
        ...formData,
        [name]: null,
      });
  
      setUpdatedFormData({ ...updatedFormData,  [name]: null });
  
      // Reset secondary details and assets if needed
      if (name === 'assetType') {
      }
    }
  };

  const handleSelectPersonChange = (data,action) => {
    if (action.name === 'allocatedPerson' && data !== null) {
      const personValue = allEmployees.find(emp => emp.employeeId === data.value);
      setSelectedOption(data)
      setFormData({
        ...formData,
        seat_allocation: personValue.seat_allocation,
        role: personValue.role,
        [action.name]: personValue.first_name,
        allocatedPersonId : Number(personValue.employeeId),
        status: data.value ? 'Assigned' : 'In Stock', // Set status to 'Assigned' if an employee is selected, otherwise set to 'In Stock'
      });

      setUpdatedFormData({ ...updatedFormData,  [action.name]: personValue.first_name });
    } else {
      setSelectedOption(null)
      setFormData({
        ...formData,
        seat_allocation: null,
        role: null,
        [action.name]: null,
        allocatedPersonId : null,
        status: data === null ? 'In Stock': 'Assigned',
        role: ''
      });

      setUpdatedFormData({ ...updatedFormData,  [action.name]: null });
    }
  };

  const handleFieldChange = (index, e) => {
    const newFields = formData.fields.map((field, idx) => {
      if (index === idx) {
          return { ...field, fieldData: e.target.value };
      }
      return field;
    });
    setFormData({ ...formData, fields: newFields });
    setUpdatedFormData({ ...updatedFormData, fields: newFields });
    calculateUpdatedFields()
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    if(value != "Assigned"){
      setFormData((prevFormData) => ({
        ...prevFormData,
        allocatedPerson: "",
        allocatedPersonId: "",
        seat_allocation: "",
        role: ""
      }))
      selectRef.current?.clearValue();
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
    if(details.status === 'Assigned'){
      setUpdatedFormData(prevState => ({
        ...prevState,
        status: value,
        allocatedPersonId: null
      }));
    }else{
      setUpdatedFormData({ ...updatedFormData,  status: value });
    }
    console.log('Updated / form', updatedFormData);
  };

  const groupFields = (arr, groupSize) => {
    const group = [];
    for (let i = 0; i < arr.length; i += groupSize) {
      group.push(arr.slice(i, i + groupSize));
    }
    return group;
  };

  const calculateUpdatedFields = () => {
    let updatedFields = [];

    for (let key in updatedFormData) {
      if (updatedFormData.hasOwnProperty(key) && key !== 'fields') {
        let prevValue = details[key] !== undefined ? details[key] : '';
        let currentValue = updatedFormData[key] !== null ? updatedFormData[key] : '';
        if (prevValue !== currentValue) {
          updatedFields.push({
            key: key,
            prev_Value: prevValue,
            current_value: currentValue
          });
        }
      }
    }
    //Check for nested fields
    if (updatedFormData.fields) {
      updatedFormData.fields.forEach((updatedField, index) => {
        let prevField = details.fields ? details.fields[index] : {};
        if (prevField && updatedField.fieldData !== prevField.fieldData && updatedField.fieldType !== 'dropdown') {
          updatedFields.push({
            key: updatedField.fieldName,
            prev_Value: prevField.fieldData !== undefined ? prevField.fieldData : '',
            current_value: updatedField.fieldData !== null ? updatedField.fieldData : ''
          });
        } else if(prevField && updatedField.fieldData !== prevField.fieldData && updatedField.fieldType === 'dropdown'){
          const prevOption = updatedField.options.find(op => Number(op.value) === Number(prevField.fieldData)) || {};
          const currentOption = updatedField.options.find(op => Number(op.value) === Number(updatedField.fieldData)) || {};
          updatedFields.push({
            key: updatedField.fieldName,
            prev_Value: prevField.fieldData !== undefined ? prevOption.label : '',
            current_value: updatedField.fieldData !== null ? currentOption.label : ''
          });
        }
      });
    }
    return updatedFields;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validation: Check specific fields
    const requiredFields = [
      'assetNumber',
      'assetType',
      'assetCategory',
      'yearOfPurchase'
    ];

    const emptyFields = requiredFields.filter(field => !formData[field] || (typeof formData[field] === 'string' && !formData[field].trim()));
    if (emptyFields.length > 0) {
      setPopup({ type: 'error', message: (emptyFields.length === 1 ? emptyFields+' field must be filled out': emptyFields +' fields must be filled out' )});
      return;
    }
    const updatedFields = calculateUpdatedFields();
    console.log('Updated Fields:', updatedFields);
    const activityDetails = {}
    if(updatedFields){
      activityDetails.assetNumber = activityData.assetNumber;
      activityDetails.employeeId = activityData.employeeId;
      activityDetails.event = activityData.event;
      activityDetails.updatedFields = updatedFields;
    }
    if(initialAllocated !== formData.employeeId){
      formData.parentAsset='';
    }
    console.log('activi',activityDetails);
    try {
      if (!token) {
        navigate("/login")
      } else {
        const res = await api.put(`${baseUrl}/api/asset-update/${objId}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        if(updatedFields.length !== 0){
          await api.post(`${baseUrl}/api/add-activity`, activityDetails, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
        }
        setActiveIndex(3);
        navigate(`/all-assets`);
      }
    } catch (error) {
      console.error('Error registering device:', error);
      if (error.response && error.response.data) {
        setPopup({ type: 'error', message: error.response.data });
      } else {
        setPopup({ type: 'error', message: 'Registration not completed due to some technical issues' });
      }
    }
  };

  const handleLoginClick = () => {
    navigate("/login")
  }

  const closePopup = () => {
    setPopup(null);
  };

  const getDropdownOptions = (status) => {
    switch (status) {
      case 'New/Verified' :
        return ['New/Verified','In Stock' ,'Assigned', 'Lost/Stolen', ]
      case 'In Stock':
        return ['In Stock','Assigned', 'To Be Decommissioned', 'Maintenance', 'Lost/Stolen', ];
      case 'Assigned':
        return ['Assigned', 'Maintenance', 'Returned', 'Lost/Stolen', ];
      case 'Returned':
        return ['Returned', 'Clearance Done', 'Maintenance', 'In Stock', 'Lost/Stolen', ];
      case 'Clearance Done':
        return ['Clearance Done', 'Maintenance', 'Lost/Stolen', ];
      case 'Maintenance':
        return ['Maintenance', 'In Stock', 'Lost/Stolen', ];
      case 'To Be Decommissioned':
        return ['To Be Decommissioned', 'Obsolete', 'Lost/Stolen', ];
      case 'Obsolete':
        return ["Obsolete"] 
      default:
        return [];
    }
  };
  // useEffect(() =>{
  //   setOptions(getDropdownOptions(assetStatus));
  //   console.log("status",assetDetailsResponse?.assetDetails?.status)
  // },[assetStatus])

  return (
    <div className='content-section col-12 col-md-12 col-xl-11 asset-main'>
      <div className='w-100'>   
        <div data-tooltip-id="tool-tip-back-button" data-tooltip-content="Back" className='backBtn' onClick={() => navigate(-1)}><HiOutlineArrowSmLeft className='nxt-prev-icon' /></div>
        <Tooltip id="tool-tip-back-button" className="asset-tooltip" border="1px solid #5c2680" place = "right"/>
      </div> 
      <div className='container'>
        <div className="assethead-head" >
          <div className="activitylog-heading">
              <h3>Update Asset</h3>
          </div>
        </div>
        <form className="asset-form" onSubmit={handleSubmit}>
          <div className='form-section'>
            <div className='refresh-container'>
              <div className='refresh-item'>
                Reset <IoMdRefresh onClick={() => window.location.reload()}/>
              </div>
            </div>
            <div className='form-section-head'>
              <FontAwesomeIcon className="font-icons" icon={faCheckCircle} />
              <span className='form-section-label'>Asset </span>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="assetNumber">Asset Number: <span className="bg-red">*</span></label>
                <input type="text" id="assetNumber" className='read-only' name="assetNumber" value={formData.assetNumber} readOnly />
              </div>

              <div className="form-group">
                <label htmlFor="assetType" className='mr-2'  >Asset Type: <span className="bg-red">*</span></label>
                <input type="text" id="assetType" name="assetType" value={formData.assetType} readOnly/>
              </div>

              <div className="form-group">
                <label htmlFor="assetCategory" className='mr-2'>Category: <span className="bg-red">*</span></label>
                <input id="assetCategory" name="assetCategory" value={formData.assetCategory} readOnly/>  
              </div>

              <div className="form-group assetyearpicker">
                  <label htmlFor="yearOfPurchase">Purchased Year: <span className="bg-red">*</span></label>
                  <input type="text" id="yearOfPurchase" className="date-own form-control" name="yearOfPurchase" value={formData.yearOfPurchase} readOnly/>
                </div>

              <div className="form-group readonly-group">
                <label htmlFor="status" className='mr-2'>Status:</label>
                <select
                  id="status"
                  className="status-selection"
                  name="status"
                  value={formData.status}
                  onChange={handleSelectChange}
                >
                  {options && options?.map((option) => (
                    <option key={option} value={option} disabled={option === "Assigned"}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className='form-section'>
            <div className='form-section-head'>
                <FontAwesomeIcon className="font-icons" icon={faUserCheck} />
                <span className='form-section-label'>Allocated Details</span>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="allocatedPerson" className='mr-2'>Allocated Person:</label>
                {
                  optionsData ?
                  <>
                    <Select 
                    isClearable={true} 
                    id="allocatedPerson" 
                    closeMenuOnSelect={true}
                    name="allocatedPerson"
                    value={selectedOption}
                    defaultValue={selectedOption}
                    onChange={handleSelectPersonChange} 
                    options={optionsData}
                    isDisabled={!instock}
                    ref={selectRef}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#e7daef',
                        primary: '#5c2680',
                      },
                    })}/>
                  </>
                
                  :
                  <>No data</>
                }
              </div>

              <div className="form-group">
                <label htmlFor="role">Role:</label>
                <input type="text" id="role" name="role" value={selectedOption?formData.role:''} readOnly />
              </div>

              <div className="form-group">
                <label htmlFor="">Seat Allocation:</label>
                <input type="text" id="seat_allocation" name="seat_allocation" value={selectedOption?formData.seat_allocation:''} readOnly />
              </div>
            </div>
          </div>

          <div className='form-section'>
            <div className='form-section-head'>
                <FontAwesomeIcon className="font-icons" icon={faCircleInfo} />
                <span className='form-section-label'>Asset Specifications </span>
            </div>
            <div className="form-row main-grid-sec">
              {formData.fields && formData.fields.length > 0 ? groupFields(formData.fields, 3).map((fieldRow, rowIndex) => (
                <div key={rowIndex} className="row inside-section">
                  {fieldRow.map((field, index) => (
                    <div key={field.fieldId} className="form-group">
                      <label>
                        {field.fieldName.charAt(0).toUpperCase() + field.fieldName.slice(1)}
                        <span className="bg-red">{field.mandatory === true ? ' *' : ''}</span>
                      </label>
                      {field.fieldType === 'text' && (
                        <input
                          type="text"
                          value={(formData.fields[rowIndex * 3 + index] && formData.fields[rowIndex * 3 + index].fieldData) || ''}
                          onChange={(e) => handleFieldChange(rowIndex * 3 + index, e)}
                          required={field.mandatory}
                        />
                      )}
                      {field.fieldType === 'date' && (
                        <input
                          type="date"
                          value={(formData.fields[rowIndex * 3 + index] && formData.fields[rowIndex * 3 + index].fieldData) || ''}
                          onChange={(e) => handleFieldChange(rowIndex * 3 + index, e)}
                          required={field.mandatory}
                        />
                      )}
                      {field.fieldType === 'dropdown' && (
                        <select
                          value={(formData.fields[rowIndex * 3 + index] && formData.fields[rowIndex * 3 + index].fieldData) || ''}
                          onChange={(e) => handleFieldChange(rowIndex * 3 + index, e)}
                          required={field.mandatory}
                        >
                          <option value="" name="">Select an option</option>
                          {field.options.map(option => (
                            <option key={option.key} value={option.value} name={option.label}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  ))}
                </div>
              )) : <div className="row inside-section"><p>No fields available</p></div>}
            </div>
          </div>

          <div className='form-section'>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="remarks">Remarks:</label>
                <textarea type="text" id="remarks" name="remarks" value={formData.remarks} onChange={handleChange} />
              </div>

            </div>
          </div>
                
          <button className='button asset-submit-btn' type="submit">Update</button>
        </form>

        {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}
      </div>

    </div>

  );
};

export default Update;
