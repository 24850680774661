import React from 'react';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import '../../../styles/exportbtn/exportbtn.css';

const ExcelExportButton = ({ data, assetTypeFilter, allAssets }) => {
  const exportToExcel = (dataToExport, filename) => {
    // Transform the data for Excel export
    const excelData = dataToExport.map(item => ({
      'Asset Number': item.assetDetails.assetNumber,
      'Asset Type': item.assetType?.assetType,
      'Category': item.assetType?.assetCategory,
      'Status': item.assetDetails.status,
      'Assigned To': item.allocatedPersonDetails
        ? `${item.allocatedPersonDetails.first_name} ${item.allocatedPersonDetails.last_name}`
        : 'Not Assigned'
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A1' });

    // Set column widths
    const columnWidths = [
      { wch: 15 }, // Asset Number
      { wch: 15 }, // Asset Type
      { wch: 12 }, // Category
      { wch: 15 }, // Status
      { wch: 25 }, // Assigned To
    ];
    ws['!cols'] = columnWidths;

    // Add style for headers - make them bold
    const headerRange = XLSX.utils.decode_range(ws['!ref']);
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const address = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!ws[address]) continue;
      ws[address].s = {
        font: {
          bold: true
        },
        alignment: {
          horizontal: 'center', // Optional: To center the text
        }
      };
    
    }

    // Create workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Assets');

    // Save file
    XLSX.writeFile(wb, filename);
  };

  const handleExport = () => {
    // Export filtered data if an asset type is set, otherwise export all data
    const dataToExport = assetTypeFilter && assetTypeFilter !== 'All'
      ? data.filter(item => item.assetType?.assetType === assetTypeFilter)
      : allAssets;

    const filename = assetTypeFilter && assetTypeFilter !== 'All'
      ? `${assetTypeFilter}_assets.xlsx`
      : 'all_assets.xlsx';

    exportToExcel(dataToExport, filename);
  };

  return (
    <div className="export-button-container">
      <button onClick={handleExport} className="export-btn">
        <FontAwesomeIcon className="fbtn" icon={faFileExcel} />
        <span className="p-2">Export</span> {/* Static button text */}
      </button>
    </div>
  );
};

export default ExcelExportButton;
