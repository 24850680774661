import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router";
import "./App.css";
import CreateAsset from "./pages/CreateAssetPage/CreateAssets";
import AssignedAssets from "./pages/AssignedAssetsPage/AssignedAssets";
import Login from "./pages/loginPage/Login";
import Update from "./pages/updatePage/Update";
import { useEffect, useState } from "react";
import ProtectedRoute from "./routes/ProtectedRoutes";
import Sidebar from "./components/sidebar/Sidebar";
import Navbar from "./components/navbar/Nabvar";
import AllAssets from "./pages/AllAssetsPage/AllAssets";
import Home from "./pages/HomePage/Home";
import AssetDetails from "./pages/AssetDetailsPage/AssetDetails";
import Activities from "./pages/ActivityLogPage/Activities"; 
import MaintenanceMode from "./pages/MaintenanceMode/MaintenanceMode";
import { corporateUrl } from '../src/config/config';
import api from '../src/api/api';

function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication
  );
  const [maintenanceStatus, setMaintenanceStatus] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  useEffect(() => {
    // Update localStorage when authentication state changes
    localStorage.setItem("authenticated", authenticated);
  }, [authenticated]);

  const [activeIndex,setActiveIndex] = useState(1);

  // Maintenance check on load/refresh
  useEffect(() => {
    const fetchMaintenanceDetails = async () => {
      const tool = 'asset';
      const accessToken = localStorage.getItem('token');
      if (!accessToken) {
        console.warn('Access token is missing, skipping maintenance check.');
        return;
      }

      try {
        const response = await api.get(`${corporateUrl}/api/get-maintenance-details/${tool}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });

        const isMaintenanceActive = response.data.maintenanceStatus || false;
        setMaintenanceStatus(isMaintenanceActive);

        if (isMaintenanceActive && !location.pathname.includes("/maintenance-mode")) {
          navigate(`/maintenance-mode/${tool}`);
        }
      } catch (error) {
        console.error("Failed to fetch maintenance details:", error);
        setMaintenanceStatus(false);
      }
    };

    fetchMaintenanceDetails();
  }, [navigate, location.pathname]);

  // On maintenance end, redirect to home page if refreshed or on maintenance page
  useEffect(() => {
    // Extract the toolName from the URL path
    const toolName = location.pathname.split("/maintenance-mode/")[1];

    if (!maintenanceStatus && location.pathname.includes("/maintenance-mode")) {
      // If toolName is "corporateportal", redirect to home or login
      if (toolName === 'asset') {
        const userId = localStorage.getItem("user_id");
        if (userId) {
          navigate(`/assigned-assets/${userId}`);
        } else {
          navigate("/login");
        }
      }
      // Otherwise, do not redirect, allow for other tools
    }
  }, [maintenanceStatus, location.pathname, navigate]);

  return (
    <div className="App">
      <div className="main-content">
      {!location.pathname.includes("/maintenance-mode") && (
        <Navbar />
      )}
        <div className="body-section">
          <div className="d-flex flex-column flex-xl-row w-100">
            
            {authenticated &&  !location.pathname.includes("/maintenance-mode") && (
              <div className="sidebar-section min-vh-0">
                <Sidebar  activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
              </div>
            )}
            
            <Routes>
              {/* <Route element={<ProtectedRoute isAuthenticated={authenticated} />} > */}

              <Route path="/create-asset/:id" element={<CreateAsset setActiveIndex={setActiveIndex}/>} />
              <Route path="/assigned-assets/:id" element={<AssignedAssets setActiveIndex={setActiveIndex}/>} />
              <Route path="/all-assets" element={<AllAssets setActiveIndex={setActiveIndex}/>} />
              <Route path="/update/:id/:objId" element={<Update  setActiveIndex={setActiveIndex}/>} />
              <Route path="/asset-details/:objId/:empId" element={<AssetDetails/>} />
              <Route path="/activities" element={<Activities />} />
              <Route path="/activities/:assetNum" element={<Activities />} />
              
              <Route path="/maintenance-mode/:tool" element={<MaintenanceMode />} />
              {/* </Route> */}
              <Route
                path="/login"
                element={<Login setAuthenticated={setAuthenticated} />}
              />
              <Route path="/" element={<Home/>} />
            </Routes>
            {/* <RegisterForm /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
