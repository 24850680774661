// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.export-btn {
    background: green;
    color: #fff;
    padding: 5px 10px;
    border: none;
    height: 33px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 14px;;
  }
  
  .export-btn:hover {
    background: #fff;
    color: green;
    border: 1px solid green;
  }
  
  .export-btn:hover .fbtn {
    color: green; /* Icon color when the button is hovered */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .fbtn {
    color: #ffffff; /* Default icon color */
    margin-left: 8px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/styles/exportbtn/exportbtn.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,0BAA0B;IAC1B,eAAe;EACjB;;EAEA;IACE,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;EACzB;;EAEA;IACE,YAAY,EAAE,0CAA0C;IACxD,2BAA2B,EAAE,4BAA4B;EAC3D;;EAEA;IACE,cAAc,EAAE,uBAAuB;IACvC,gBAAgB;EAClB","sourcesContent":[".export-btn {\n    background: green;\n    color: #fff;\n    padding: 5px 10px;\n    border: none;\n    height: 33px;\n    border-radius: 5px;\n    text-transform: capitalize;\n    font-size: 14px;;\n  }\n  \n  .export-btn:hover {\n    background: #fff;\n    color: green;\n    border: 1px solid green;\n  }\n  \n  .export-btn:hover .fbtn {\n    color: green; /* Icon color when the button is hovered */\n    transition: color 0.3s ease; /* Smooth color transition */\n  }\n  \n  .fbtn {\n    color: #ffffff; /* Default icon color */\n    margin-left: 8px;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
