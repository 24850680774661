import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/styles.css'; // Import the CSS file
import Popup from '../../components/popup/Popup';
import { useNavigate} from 'react-router-dom';
import baseUrl from '../../config/config';
import corporateUrl from '../../config/config';
// import jwt from 'jsonwebtoken';
// import Cookies from 'js-cookie';
// import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import api from '../../api/api';

const Login = ({setAuthenticated}) => {

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const fetchMaintenanceDetails = async (accessToken) => {
 
    const tool = 'asset'; // Example tool name
    if (accessToken) {
      try {
        // const csrfToken = await fetchCsrfToken();
        const response = await api.get(`${corporateUrl}/api/get-maintenance-details/${tool}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching maintenance details:', error);
        return null;
      }
    }
  };

  const fetchData = async () => {
    try {
      const decodedToken = jwtDecode(token);
      const { employeeId } = decodedToken;

      
      const response = await api.post(`${baseUrl}/api/login`, { employeeId: Number(employeeId) });
      console.log("Asset login response",response)

      const maintenanceResponse = await fetchMaintenanceDetails(response.data.accessToken);

      // If there's an active maintenance, redirect to a maintenance page
      if (maintenanceResponse && maintenanceResponse.maintenanceStatus === true) {
        localStorage.setItem('token', token);
        setPopup({ type: 'success', message: response.data });
        setFormData({ employeeId: '' });
        localStorage.setItem('authenticated', true);
        setAuthenticated(true);
        navigate(`/maintenance-mode/${maintenanceResponse.toolName}`); 
        return; // Stop further execution
      }
  
      if (response.data.message === 'User successfully logged in') {
        // User found, navigate to the status page
        localStorage.setItem('token', token);
        setPopup({ type: 'success', message: response.data });
        setFormData({ employeeId: '' });
        localStorage.setItem('authenticated', true);
        setAuthenticated(true);
        navigate(`/assigned-assets/${employeeId}`);
      } else {
        // User not found
        setPopup({ type: 'error', message: 'User not found. Please check the Employee ID and Password' });
      }
    } catch (error) {
      console.error('Error logging in:', error);
      // setPopup({ type: 'error', message: 'Something went wrong. Please try again.' });
    }
  };

  useEffect(() => {
    fetchMaintenanceDetails();
    fetchData();
  }, [token]); // Make sure to include token in the dependency array to trigger the effect when token changes  

  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    employeeId: '',
    password: '',
  });
  const [popup, setPopup] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validation: Check if any input field is empty
    if (Object.values(formData).some((value) => !value.trim())) {
      setPopup({ type: 'error', message: 'All fields must be filled out' });
      return;
    }

    try {
        const response = await api.post(`${baseUrl}/api/login`, { employeeId: formData.employeeId , password: formData.password});
        console.log(response)

        const maintenanceResponse = await fetchMaintenanceDetails(response.data.accessToken);
      
        // If there's an active maintenance, redirect to a maintenance page
        if (maintenanceResponse && maintenanceResponse.maintenanceStatus === true) {
          navigate('/maintenance-mode');
          return; // Stop further execution
        }
    
        if (response.data.message === 'User successfully logged in') {
          // User found, navigate to the status page
          localStorage.setItem('token', response.data.accessToken);
          setPopup({ type: 'success', message: response.data });
          setFormData({ employeeId: '' });
          localStorage.setItem('authenticated', true);
          setAuthenticated(true)
          navigate(`/assigned-assets/${formData.employeeId}`);
        } else {
          // User not found
          setPopup({ type: 'error', message: 'User not found. Please check the Employee ID and Password' });
        }
      } catch (error) {
        console.error('Error logging in:', error);
        setPopup({ type: 'error', message: 'Something went wrong. Please try again.' });
      }
  };

  const closePopup = () => {
    setPopup(null);
  };

  return (
    <div className='loading-sec center'>
      <div className='loading-image'><img  src="/images/fade-stagger-circles.svg" alt="Profile Pic" className='loading-image-item'/></div>
      <div className='loading-text'>Loading</div>

      {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}
    </div>

  );
};

export default Login;
