import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import axios from 'axios';
import '../../styles/assetModify/assetModify.css'; // Import the CSS file
import '../../styles/form/form.css'; 
import Popup from '../../components/popup/Popup';
import { useNavigate, useParams } from 'react-router-dom';
import baseUrl, { corporateUrl } from '../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUserCheck,faCircleInfo,faAngleUp,faAngleDown } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import up_arrow from './../../svgs/chevron-down-circle-outline.svg';
import down_arrow from './../../svgs/chevron-up-circle-outline.svg';
import api from '../../api/api';

import icons from '../../data/assetTypes';
import { MdSpeaker } from "react-icons/md";

const CreateAsset = ({setActiveIndex}) => {
  const { id } = useParams();
  const token = localStorage.getItem('token'); //retrive the token from the local storage
  const event = 'Created';
  const isAdmin =localStorage.getItem("access_type");
  const navigate = useNavigate();
  const [assetNumIdData,setAssetNumId] = useState('');
  const [formData, setFormData] = useState({
    assetNumId: '',
    assetNumber: '',
    employeeId: Number(id),
    assetTypeId:'',
    status: 'New/Verified',
    allocatedPerson: '',
    allocatedPersonId: '',
    seat_allocation: '',
    roleName: '',
    yearOfPurchase: '',
    remarks: '',
    parentAsset: '',
    fields:[]
  });
  const [datepickerSelected,setDatepickerSelected] = useState('');
  const [optionsData, setOptionsData] = useState([]);
  const [activityData, setActivityData] = useState({
    assetNumber: '',
    employeeId: id,
    event: event,
  });

  const [popup, setPopup] = useState(null);
  const [allEmployees, setAllEmployees] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);
  const [details, setDetails] = useState([]);
  const [secondaryAssets, setSecondaryAssets] = useState([]);
  const [secondaryAssetDetails, setSecondaryAssetDetails] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState('');
  const [assetIcons,setAssetIcons] = useState(null);
  const [assetNumberSelection,setAssetNumberSelection] = useState('');
  const [assetTypes,setAssetTypes] = useState([]);
  const [fields, setFields] = useState([]);
  const fetchDetails = async () => {
    try {
      if (!token) {
          navigate("/login");
      } else {
          const response = await api.get(`${baseUrl}/api/all-assets-details`, {
              headers: {
                  'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
              },
          });
          const filtered = response.data.results.filter(asset => 
            asset.assetType.assetCategory && 
            asset.assetType.assetCategory.toLowerCase() === 'secondary' && 
            asset.assetDetails.status.match('In Stock')
          );
          setDetails(filtered);
          const asseteData = response.data.results;
          const iconsMap = {};
          asseteData.forEach(asset => {
            const matchingIcon = icons.find(icon => icon['type'] === asset.assetType.assetType);
            if (matchingIcon) {
              iconsMap[asset.assetType.assetType] = matchingIcon["icon"];
            } else {
              console.warn(`No matching icon found for assetType: ${asset.assetType.assetType}`);
            }
          });
          setAssetIcons(iconsMap);  
      }
    } catch (error) {
        console.error('Error fetching details:', error);
    }
  };
  useEffect(() => {
    getAllEmployees();
    fetchDetails();
  },[])

  useEffect(() => {
    console.log("create assets 101: ", details);
  }, [details]);

  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
          const response =await api.get(`${baseUrl}/api/all-asset-types`, {
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          setAssetTypes(response.data);
      } catch (error) {
          console.error('Error fetching asset types:', error);
      }
    };
    fetchAssetTypes();
  }, []);
  
  const getAllEmployees = async () => {
    
    const token = localStorage.getItem('token');
    try {
      const response = await api.get(`${corporateUrl}/api/all-users-asset`, {
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      setAllEmployees(response.data)
      const options = response.data.map(employee => ({
        value: employee.employeeId,
        label: employee.first_name+' '+employee.last_name+' ( '+employee.employeeId+' ) ',
      }));
      setOptionsData(options);
    } catch (error) {
      console.error('Error fetching employees', error);
    }
  };

  const handleAssetTypeChange = async (e) => {
    const assetId = e.target.value;
    const asssetDetails = assetTypes.find(type => type.assetTypeId.toString()  === assetId);
    if(asssetDetails){
      setFormData({
        ...formData,
        assetTypeId: assetId,
        assetCategory: asssetDetails.assetCategory,
        assetType: asssetDetails.assetType,
        fields: []
      });
      let sanitizedValue = asssetDetails.assetType.replace('/', '');
      const newAssetNumberSelection = 'PSL/' + sanitizedValue.slice(0, 3).toUpperCase();

      // Preserve the year and number part if they exist
      const parts = assetNumberSelection.split('/');
      const currentYear = parts[2] || '';
      const numberPart = parts[3] || '';

      // Set assetNumberSelection in the desired format
      setAssetNumberSelection(newAssetNumberSelection + '/' + currentYear + (numberPart ? '/' + numberPart : ''));
    }else{
      setFormData({
        ...formData,
        assetTypeId: '',
        assetCategory: '',
        assetType: '',
        fields: []
      });
      setAssetNumberSelection('');
      setDatepickerSelected('')
      setFields([]);
    }
    try {
      const response =await api.get(`${baseUrl}/api/get-fields/${assetId}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
      });
      if (response.data && response.data.length > 0) {
        setFields(response.data);
        // Initialize formData.fields based on the fetched fields
        setFormData(prevFormData => ({
            ...prevFormData,
            fields: response.data.map(field => ({ feildId:field.feildId, value: '' }))
        }));
    } else {
        setFields([]);
        setFormData(prevFormData => ({
            ...prevFormData,
            fields: []
        }));
    }
      
    } catch (error) {
        console.error('Error fetching fields:', error);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name !== 'allocatedPerson' && value !== null) {
      // Update formData and activityData when a value is selected
      setFormData({
        ...formData,
        [name]: value,
      });
  
      setActivityData({
        ...activityData,
        [name]: value,
      });
  
      // Handle assetType specific logic
      // if (name === 'assetType') {
        

      //   const icon = icons.find(icon => icon.type === value); // Use === for comparison
      //   if (icon) {
      //     // setFormData({
      //     //   ...formData,
      //     //   assetCategory: icon.category,
      //     //   assetType: value
      //     // });
      //     // Reset secondary details and assets if needed
      //     setSecondaryAssetDetails([]);
      //     setSecondaryAssets([]);
      //   }
      // }
    } else {
      // Handle resetting formData and activityData when value is null or allocatedPerson is changed
      setFormData({
        ...formData,
        [name]: null,
      });
  
      setActivityData({
        ...activityData,
        [name]: null,
      });
  
      // Reset secondary details and assets if needed
      if (name === 'assetType') {
        setSecondaryAssetDetails([]);
        setSecondaryAssets([]);
      }
    }
  };
  

  const handleSelectPersonChange = (data,action) => {
    if (action.name === 'allocatedPerson' && data !== null) {
      
      console.log('empDA',data.value)
      console.log('EMP',allEmployees);
      const personValue = allEmployees?.find(emp => emp.employeeId === data.value);
      setSelectedOption(data)
      setFormData({
        ...formData,
        [action.name]: personValue.first_name+ ' '+personValue.last_name,
        seat_allocation: personValue.seat_allocation,
        role: personValue.role,
        allocatedPersonId : Number(personValue.employeeId),
        status: data.value ? 'Assigned' : 'New/Verified', // Set status to 'Assigned' if an employee is selected, otherwise set to 'In Stock'
      });

      setActivityData({
        ...activityData,
        [action.name]: personValue.first_name,
        allocatedPersonId: Number(personValue.employeeId)
      });
    } else {
      setSelectedOption(null)
      setFormData({
        ...formData,
        [action.name]: "",
        seat_allocation: "",
        role: "",
        allocatedPersonId : "",
        status: data === null ? 'New/Verified': 'Assigned' ,
      });

      setActivityData({
        ...activityData,
        [action.name]: null,
      });
    }
  };

  const handleCardClick = (assetNumber) => {
    const isSelected = secondaryAssets.includes(assetNumber);
    let updatedSecondaryAssets;
    if (isSelected) {
      updatedSecondaryAssets = secondaryAssets.filter(asset => asset !== assetNumber);
    } else {
      updatedSecondaryAssets = [...secondaryAssets, assetNumber];
    }
    setSecondaryAssets(updatedSecondaryAssets);
    const updatedSecondaryAssetDetails = details.filter(item => updatedSecondaryAssets.includes(item.assetDetails.assetNumber));
    setSecondaryAssetDetails(updatedSecondaryAssetDetails);
  };

  const handleYearChange = async(date) =>{
    setDatepickerSelected(date);
    let assetNumberStatusResponse;
    // Extract year from date
    const year = new Date(date).getFullYear();
    if(year){
       assetNumberStatusResponse = await api.get(`${baseUrl}/api/asset-number-state/${year}/${formData.assetTypeId}`, {
        headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
    });
    
    setAssetNumId(assetNumberStatusResponse.data.nextAssetNumber);
    }

    // Preserve the first part of assetNumberSelection
    const parts = assetNumberSelection.split('/');
    const firstPart = parts.slice(0, 2).join('/');

    // Update assetNumberSelection with the new year and add number part '1000'
    setAssetNumberSelection(firstPart + '/' + year.toString() + '/'+ assetNumberStatusResponse.data.nextAssetNumber);
    setFormData({
      ...formData,
      yearOfPurchase: year,
    });

    setActivityData({
      ...activityData,
      yearOfPurchase: date,
    });
  }
 
  useEffect(() => {
    if(assetNumberSelection.length > 0){
      setFormData({
        ...formData,
        assetNumId:assetNumIdData,
        assetNumber: assetNumberSelection,
      });
  
      setActivityData({
        ...activityData,
        assetNumber: assetNumberSelection,
      });
    }
  }, [assetNumberSelection,datepickerSelected]);

  const handleFieldChange = (index, e) => {
    const newFields = formData.fields.map((field, idx) => {
      if (index === idx) {
          return { ...field, value: e.target.value };
      }
      return field;
    });
    setFormData({ ...formData, fields: newFields });
  };

  const groupFields = (arr, groupSize) => {
    const group = [];
    for (let i = 0; i < arr.length; i += groupSize) {
      group.push(arr.slice(i, i + groupSize));
    }
    return group;
  };

  const handleShowMore = (assetNumber) =>{
    setSelectedDescription(assetNumber);
  }

  const renderFieldDetails = (fieldDetails = [], specifications = []) => {
    return fieldDetails.map((field) => {
        const spec = specifications.find((spec) => spec.feildId === field.feildId);
        let option;
        
        if (field.fieldType === "dropdown" && spec) {
          const valueAsNumber = Number(spec.fieldValue);
          option = field.options ? field.options.find((option) => option.value === valueAsNumber) : '';
        }
      return (
        <>
            <div className='asset_details_row_section' key={field.fieldId}>
                <div className='asset_details_label'>{field.fieldName}:</div>
                <div className='asset_details_desc'>{field.fieldType === "dropdown" ? option?.label:spec ? spec.fieldValue : 'N/A'}</div>
            </div>
            {isAdmin === '1' && field.feildName?.toLowerCase() === 'windows license' && (
                <div className='asset_details_row_section' key={field.fieldId}>
                    <div className='asset_details_label'>{field.fieldName}:</div>
                    <div className='asset_details_desc'>{field.fieldType === "dropdown" ? option?.label:spec ? spec.fieldValue : 'N/A'}</div>
                </div>
            )}
        </>
        );
        
    });
  } ;

  const handleSubmit = async (e) => {
    e.preventDefault();


    // Validation: Check specific fields
    const requiredFields = [
      'assetNumber',
      'employeeId',
      'assetTypeId',
      'assetCategory',
      'yearOfPurchase'
    ];

    const emptyFields = requiredFields.filter(field => !formData[field] || (typeof formData[field] === 'string' && !formData[field].trim()));
    if (emptyFields.length > 0) {
      setPopup({ type: 'error', message: (emptyFields.length === 1 ? emptyFields+' field must be filled out': emptyFields +' fields must be filled out' )});
      return;
    }

    setActivityData(prevState => {
      let updatedFields = [];
    
      if (prevState.allocatedPerson && prevState.allocatedPersonId) {
        updatedFields = [
          {
            key: "allocatedPerson",
            prev_Value: "", // Assuming no previous value; update if needed
            current_value: prevState.allocatedPerson || ""
          },
          {
            key: "allocatedPersonId",
            prev_Value: "", // Assuming no previous value; update if needed
            current_value: prevState.allocatedPersonId || ""
          }
        ];
      }
    
      // Ensure updatedFields is always an array and merge with any existing fields
      return {
        ...prevState,
        updatedFields: [...(prevState.updatedFields || []), ...updatedFields]
      };
    });

    try {
      if (!token) {
        navigate("/login")
      } else {
        const res = await api.post(`${baseUrl}/api/asset-create`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        
        const activityRes = await api.post(`${baseUrl}/api/add-activity`, activityData, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });         

        const employee1 = allEmployees.find(emp => emp.employeeId === id);
        const allocatedPersonName = employee1 ? employee1.first_name : '';
    
        // Assuming secondaryAssetDetails is an array of objects, each containing an objId
        for (const subAsset of secondaryAssetDetails) {
          const objId = subAsset.assetDetails._id; 
          console.log('objId',objId);// Extract objId from the current device object
          subAsset.status = 'Assigned';
          subAsset.allocatedPersonId = Number(formData.allocatedPersonId);
          subAsset.parentAsset = formData.assetNumber;
          subAsset.fields = [];
          console.log('subasset',subAsset);
          const response = await api.put(`${baseUrl}/api/asset-update/${objId}`, subAsset, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
    
          // Do something with the response if needed
          console.log('Asset details for objId:', objId, response.data);
        }
    
        //setPopup({ type: 'success', message: res.data });
        setFormData({
          assetNumber: '',
          employeeId: id,
          assetTypeId:'',
          assetCategory: '',
          status: '',
          allocatedPersonId: '',
          yearOfPurchase: '',
          remarks: '',
          parentAsset: '',
          fields:[]
        });
    
        if (res.data === 'Already Registered With This employee id') {
          // User found, navigate to the status page
          setPopup({ type: 'error', message: 'Already Registered The Device' });
        } else {
          setPopup({ type: 'success', message: res.data });
          setActiveIndex(3);
          navigate(`/all-assets`);
        }
      }
    } catch (error) {
      console.error('Error registering device:', error);
      if (error.response && error.response.data) {
        console.log('errorData',error.response.data);
        setPopup({ type: 'error', message: "there is a error" });
      } else {
        setPopup({ type: 'error', message: 'Registration not completed due to some technical issues' });
      }
    }
    
  };

  const handleLoginClick = () => {
    navigate("/login")
  }

  const closePopup = () => {
    setPopup(null);
  };

  return (
    <div className="content-section col-12 col-md-12 col-xl-11 asset-main">
      <div className='container'>
        <div className="assethead-head" >
                <div className="activitylog-heading">    
                    <h3>New Asset</h3>
                </div>
        </div>
        <form className="asset-form" onSubmit={handleSubmit}>
          <div className='form-section'>
            <div className='form-section-head'>
              <FontAwesomeIcon className="font-icons" icon={faCheckCircle} />
              <span className='form-section-label'>Asset </span>
            </div>
            <div className="form-row asset-first-details-section">

              <div className="form-group">
                <label htmlFor="assetType">Asset Type:<span className="bg-red">*</span></label>
                {/* <select id="assetType" name="assetType" value={formData.assetType} onChange={handleChange}>
                  {icons.map((device, index) => (
                    <option key={index} value={device.type}>{device.type}</option>
                  ))}
                </select> */}
                <select id="assetType" name="assetType" value={formData.assetTypeId} onChange={handleAssetTypeChange} required>
                    <option value="">Select Asset Type</option>
                    {assetTypes.map(type => (
                        <option key={type.assetTypeId} value={type.assetTypeId}>
                            {type.assetType}
                        </option>
                    ))}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="assetCategory" className='mr-2'>Category: <span className="bg-red">*</span></label>
                <input id="assetCategory" name="assetCategory" value={formData.assetCategory} readOnly/>
              </div>

              <div className="form-group assetyearpicker">
                <label htmlFor="yearOfPurchase">Purchased Year: <span className="bg-red">*</span></label>
                <DatePicker
                  selected={datepickerSelected}
                  onChange={handleYearChange}
                  showYearPicker
                  dateFormat="yyyy"
                  className="date-own form-control"
                  placeholderText="Select year"
                  disabled={!formData.assetTypeId}
                />
              </div>

              <div className="form-group">
                <label htmlFor="assetNumber">Asset Number: <span className="bg-red">*</span></label>
                <input type="text" id="assetNumber" name="assetNumber" value={assetNumberSelection} readOnly/>
              </div>

              <div className="form-group readonly-group">
                <label htmlFor="status" className='mr-2'>Status:</label>
                <input
                  type="text"
                  id="readOnlyStatus"
                  className={formData.status == 'New/Verified' ? 'read-only instock' : 'read-only'}
                  name="readOnlyStatus"
                  value={formData.status}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className='form-section'>
            <div className='form-section-head'>
                <FontAwesomeIcon className="font-icons" icon={faUserCheck} />
                <span className='form-section-label'>Allocated </span>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="allocatedPerson" className='mr-2'>Allocated Details:</label>
                {
                  optionsData ?
                  <>
                    <Select 
                    isClearable={true} 
                    id="allocatedPerson" 
                    closeMenuOnSelect={true}
                    name="allocatedPerson"
                    value={selectedOption}
                    defaultValue={selectedOption}
                    onChange={handleSelectPersonChange} 
                    options={optionsData}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#e7daef',
                        primary: '#5c2680',
                      },
                    })}/>
                  </>
                
                  :
                  <>No data</>
                }
              </div>

              <div className="form-group">
                <label htmlFor="role">Role:</label>
                <input type="text" id="role" name="role" value={selectedOption?formData.role:''} readOnly />
              </div>
              <div className="form-group">
                <label htmlFor="">Seat Allocation:</label>
                <input type="text" id="seat_allocation" name="seat_allocation" value={selectedOption?formData.seat_allocation:''} readOnly />
              </div>
            </div>
          </div>

          {(formData.assetCategory && formData.assetCategory.toLowerCase() === 'primary') && (formData.allocatedPerson != '') &&(
            <div className='form-section-description'>
              <div className='form-section-head'>
                  <FontAwesomeIcon className="font-icons" icon={faUserCheck} />
                  <span className='form-section-label'>Secondary Devices </span>
              </div>
              <div className="form-row-h">
              {details.length !==0 ? (<label htmlFor="allocatedPerson" className='col-4 mr-2 pl-2'>Available devices</label>):(<label htmlFor="allocatedPerson" className='col-4 mr-2 pl-2'>No available secondary devices</label>)}
                <div className="form-group_horizontal">
                  {
                    optionsData ?      
                    <>
                        {details.map((item, index) =>
                            <div className='asset-item-main col-auto'>
                                <div className={`asset-card ${secondaryAssets.includes(item.assetDetails?.assetNumber) ? 'selected' : ''}`} onClick={() => handleCardClick(item.assetDetails?.assetNumber)}>
                                    <div className='asset-item h-100' style={{width:"24rem"}}>
                                      <div className='asset-content-inner  col-12'>
                                        <div className='asset-innersec'>
                                            <div className='asset-item-image-title'>
                                              <div className='asset-item-image-inner'>
                                                  {item?.assetType?.assetType === "Speaker"? <MdSpeaker />:<FontAwesomeIcon className="font-icons" icon={assetIcons[item.assetType?.assetType]}/>}
                                              </div>
                                              <div className='asset-item-number'>
                                                  {item.assetDetails?.assetNumber}
                                              </div>
                                            </div>
                                            <div className='asset-item-action'>
                                            </div>
                                          </div>   
                                        

                                        <div className='asset-item-type'>
                                            <span className='asset-label'>Asset Type : </span>
                                            <span className='asset-data'>{item.assetType?.assetType}</span>
                                        </div>

                                        <div className='asset-item-category'>
                                            <span className='asset-label'>Category : </span>
                                            <span className='asset-data'>{item.assetType?.assetCategory}</span>
                                        </div>
                                        
                                        <div className={item.allocatedPersonDetails ?'asset-item-allocated d-flex justify-content-between':'asset-item-allocated d-flex justify-content-end'}>
                                            {item.allocatedPersonDetails ?
                                            <div className='asset-allocated-person'>
                                                <span className='asset-label'>Allocated Person : </span>
                                                <span className='asset-data'>{item.allocatedPersonDetails.first_name+' '+item.allocatedPersonDetails.last_name}</span>
                                            </div>
                                            : ''}
                                            <div className='asset-status'>
                                                <span className={item.assetDetails.status == 'Assigned' ? 'active' : ''}>{item.assetDetails.status}</span>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>

                            </div>
                        )}
                    </>
                  
                    :
                    <>No data</>
                  }
                </div>
                {(formData.assetCategory && formData.assetCategory.toLowerCase() === 'primary') && (formData.allocatedPerson !== '') && (secondaryAssets.length > 0) && (
                  <div className='show-details-container'>
                    <div className='form-section-head'>
                      <FontAwesomeIcon className="font-icons" icon={faUserCheck} />
                      <span className='form-section-label'>Secondary Device Details</span>
                    </div>
                    {console.log('secondary',secondaryAssetDetails)}
                    {secondaryAssetDetails.map((secondaryAssetDetails, index) => (
                      <div key={index} className="description_secondary_asset_create_asset">
                      <div className='form-row'>
                        <div className="form-group">
                          <label htmlFor="assetNumber" className='show_info_create_asset_secondary'>Asset Number:</label>
                          <label>{secondaryAssetDetails.assetDetails?.assetNumber}</label>
                        </div>

                        <div className="form-group">
                          <label htmlFor="assetType" className='show_info_create_asset_secondary'>Asset Type:</label>
                          <label>{secondaryAssetDetails.assetType?.assetType}</label>
                        </div>

                        <div className="form-group">
                          <label htmlFor="assetCategory" className='mr-2 show_info_create_asset_secondary'>Category:</label>
                          <label>{secondaryAssetDetails.assetType?.assetCategory}</label>
                        </div>

                        <div className="form-group assetyearpicker">
                          <label htmlFor="yearOfPurchase" className='show_info_create_asset_secondary'>Purchased Year:</label>
                          <label>{secondaryAssetDetails.assetDetails.yearOfPurchase}</label>
                        </div>

                        <div className="form-group readonly-group">
                          <label htmlFor="status" className='mr-2 show_info_create_asset_secondary'>Status:</label>
                          <label>{secondaryAssetDetails.assetDetails?.status}</label>
                        </div>

                        <div className='show_more' onClick={() => setSelectedDescription(prevDescription => prevDescription === secondaryAssetDetails.assetDetails.assetNumber ? '' : secondaryAssetDetails.assetDetails.assetNumber)}>
                          {/* Show More */} 
                          {secondaryAssetDetails.assetDetails.assetNumber === selectedDescription ? '' : ''} 
                          <div className='secondary-item-arrow'>
                          {secondaryAssetDetails.assetDetails.assetNumber === selectedDescription ? <FontAwesomeIcon className="font-icons" icon={faAngleUp} />:<FontAwesomeIcon className="font-icons" icon={faAngleDown} />}
                          </div>
                        </div>

                      </div>
                      {secondaryAssetDetails.assetDetails.assetNumber === selectedDescription && (
                          <>
                            <div className='form-row'>
                            <div className="form-group">
                                <label htmlFor="allocatedPerson" className='mr-2 show_info_create_asset_secondary'>Employee Id:</label>
                                <label>{secondaryAssetDetails.allocatedPersonDetails?.employeeId}</label>
                              </div>

                              <div className="form-group">
                                <label htmlFor="allocatedPerson" className='mr-2 show_info_create_asset_secondary'>Allocated Person:</label>
                                <label>{secondaryAssetDetails?.allocatedPersonDetails?secondaryAssetDetails.allocatedPersonDetails?.first_name+' '+secondaryAssetDetails?.allocatedPersonDetails?.last_name:''}</label>
                              </div>

                              <div className="form-group">
                                <label htmlFor="role" className='show_info_create_asset_secondary'>Role:</label>
                                <label>{secondaryAssetDetails.allocatedPersonDetails?.roleName}</label>
                              </div>

                              <div className="form-group">
                                <label htmlFor="role" className='show_info_create_asset_secondary'>Seat Allocation:</label>
                                <label>{secondaryAssetDetails.allocatedPersonDetails?.seat_allocation}</label>
                              </div>
                            </div>

                            <div className="form-row main-grid-sec">
                              <div className='row inside-section'>
                                {renderFieldDetails(secondaryAssetDetails?.fieldDetails, secondaryAssetDetails?.specifications)}
                              </div>
                            </div>

                            <div className="form-row">
                              <div className="form-group">
                                <label htmlFor="remarks" className='show_info_create_asset_secondary'>Remarks:</label>
                                <label>{secondaryAssetDetails.assetDetails.remarks}</label>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}

              </div>
            </div>
          )}

          <div className='form-section'>
            <div className='form-section-head'>
              <FontAwesomeIcon className="font-icons" icon={faCircleInfo} />
              <span className='form-section-label'>Asset Specifications</span>
            </div>
            <div className="form-row main-grid-sec">
              {fields && fields.length > 0 ? groupFields(fields, 3).map((fieldRow, rowIndex) => (
                <div key={rowIndex} className="row inside-section">
                  {fieldRow.map((field, index) => (
                    <div key={field.fieldId} className="form-group">
                      <label>
                        {field.fieldName?.charAt(0).toUpperCase() + field.fieldName?.slice(1)}
                        <span className="bg-red">{field.mandatory === true ? ' *' : ''}</span>
                      </label>
                      {field.fieldType === 'text' && (
                        <input
                          type="text"
                          value={(formData.fields[rowIndex * 3 + index] && formData.fields[rowIndex * 3 + index].value) || ''}
                          onChange={(e) => handleFieldChange(rowIndex * 3 + index, e)}
                          required={field.mandatory}
                        />
                      )}
                      {field.fieldType === 'date' && (
                        <input
                          type="date"
                          value={(formData.fields[rowIndex * 3 + index] && formData.fields[rowIndex * 3 + index].value) || ''}
                          onChange={(e) => handleFieldChange(rowIndex * 3 + index, e)}
                          required={field.mandatory}
                        />
                      )}
                      {field.fieldType === 'dropdown' && (
                        <select
                          value={(formData.fields[rowIndex * 3 + index] && formData.fields[rowIndex * 3 + index].value) || ''}
                          onChange={(e) => handleFieldChange(rowIndex * 3 + index, e)}
                          required={field.mandatory}
                        >
                          <option value="">Select an option</option>
                          {field.options.map(option => (
                            <option key={option.key} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  ))}
                </div>
              )) : <div className="row inside-section"><p>No fields available</p></div>}
            </div>
          </div>

          <div className='form-section'>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="remarks">Remarks:</label>
                <textarea type="text" id="remarks" name="remarks" value={formData.remarks} onChange={handleChange} />
              </div>

            </div>
          </div>
                
          <button className='button asset-submit-btn' type="submit">Create</button>
        </form>

        {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}
      </div>
    </div>

  );
};

export default CreateAsset;
